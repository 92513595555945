import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideDrawer from "./SideDrawer";

const Logo = styled("div")(() => ({ padding: "27px" }));

const Root = styled("div")(() => ({
  height: "110px",
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Button = styled(IconButton)(() => ({
  height: "33px",
  width: "33px",
  padding: "27px",
  marginRight: "40px",
  zIndex: 1301,
}));

const SvgIconStyled = styled(SvgIcon)(() => ({
  width: "100%",
  height: "100%",
}));
const Header = () => {
  const [open, setOpen] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    open && setOpen(false);
  }, [search]);

  return (
    <Root>
      <Logo>
        <SvgIconStyled
          focusable="false"
          fill="#fff"
          width="348"
          height="58.84"
          viewBox="0 0 348 58.84"
        >
          <title>Asset 2</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g>
                <path d="M7,35.09A13.15,13.15,0,0,1,1.62,31.4l.72-.68a13.15,13.15,0,0,0,5,3.44,17.56,17.56,0,0,0,6.93,1.37q5.64,0,8.51-2.25a7.07,7.07,0,0,0,2.87-5.82A6.35,6.35,0,0,0,24.17,23a9.36,9.36,0,0,0-3.7-2.49,49.37,49.37,0,0,0-5.92-1.7,55.24,55.24,0,0,1-6.31-1.89,9.87,9.87,0,0,1-4-2.79A7.49,7.49,0,0,1,2.65,9.1,8.45,8.45,0,0,1,4,4.55,8.88,8.88,0,0,1,8,1.24,16.4,16.4,0,0,1,14.76,0a18.2,18.2,0,0,1,5.69.91,16.53,16.53,0,0,1,4.81,2.4l-.57.83A15.89,15.89,0,0,0,20,1.78,17.69,17.69,0,0,0,14.81,1Q9.38,1,6.53,3.28A7.26,7.26,0,0,0,3.69,9.16a6.46,6.46,0,0,0,1.5,4.47,9.2,9.2,0,0,0,3.72,2.51,58.45,58.45,0,0,0,6,1.76,54.07,54.07,0,0,1,6.26,1.88,9.94,9.94,0,0,1,3.93,2.75,7.23,7.23,0,0,1,1.61,4.93A8.18,8.18,0,0,1,25.36,32a9.1,9.1,0,0,1-4.11,3.28,17.24,17.24,0,0,1-6.91,1.22A19.23,19.23,0,0,1,7,35.09Z" />
                <path d="M41.86,34.14a17.49,17.49,0,0,1-6.67-6.55,18.14,18.14,0,0,1-2.43-9.33,18.2,18.2,0,0,1,2.43-9.34,17.53,17.53,0,0,1,6.67-6.54A19,19,0,0,1,51.33,0a19,19,0,0,1,9.46,2.38,17.53,17.53,0,0,1,6.67,6.54,18.2,18.2,0,0,1,2.43,9.34,18.14,18.14,0,0,1-2.43,9.33,17.49,17.49,0,0,1-6.67,6.55,20,20,0,0,1-18.93,0Zm18.39-.86a16.62,16.62,0,0,0,6.31-6.2,17.27,17.27,0,0,0,2.3-8.82,17.27,17.27,0,0,0-2.3-8.82,16.58,16.58,0,0,0-6.31-6.21,18.83,18.83,0,0,0-17.85,0,16.58,16.58,0,0,0-6.31,6.21,17.27,17.27,0,0,0-2.3,8.82,17.27,17.27,0,0,0,2.3,8.82,16.62,16.62,0,0,0,6.31,6.2,18.83,18.83,0,0,0,17.85,0Z" />
                <path d="M83.19,32.64q-3.68-3.88-3.68-11.43V.16h1v21q0,7.13,3.34,10.75t9.59,3.62q6.26,0,9.59-3.62t3.34-10.75V.16h1V21.21q0,7.54-3.67,11.43T93.48,36.52C89.07,36.52,85.63,35.22,83.19,32.64Z" />
                <path d="M149.34.16v36.2h-.88L121.93,2.07V36.36h-1V.16h.88L148.3,34.45V.16Z" />
                <path d="M163.1.16h13.7a20.69,20.69,0,0,1,10,2.32A16.82,16.82,0,0,1,193.48,9a18.12,18.12,0,0,1,2.41,9.31,18.12,18.12,0,0,1-2.41,9.31A16.79,16.79,0,0,1,186.76,34a20.58,20.58,0,0,1-10,2.33H163.1Zm13.6,35.22a19.87,19.87,0,0,0,9.54-2.2,15.65,15.65,0,0,0,6.36-6.1,17.38,17.38,0,0,0,2.25-8.82,17.33,17.33,0,0,0-2.25-8.82,15.59,15.59,0,0,0-6.36-6.1,19.87,19.87,0,0,0-9.54-2.2H164.13V35.38Z" />
                <path d="M205.82.16h13.71a20.68,20.68,0,0,1,9.95,2.32A16.91,16.91,0,0,1,236.21,9a18.21,18.21,0,0,1,2.4,9.31,18.21,18.21,0,0,1-2.4,9.31A16.88,16.88,0,0,1,229.48,34a20.57,20.57,0,0,1-9.95,2.33H205.82Zm13.6,35.22a19.91,19.91,0,0,0,9.55-2.2,15.78,15.78,0,0,0,6.36-6.1,17.38,17.38,0,0,0,2.25-8.82,17.33,17.33,0,0,0-2.25-8.82A15.71,15.71,0,0,0,229,3.34a19.91,19.91,0,0,0-9.55-2.2H206.85V35.38Z" />
                <path d="M274.25,36.36,265,23.59a22,22,0,0,1-4.24.36H249.58V36.36h-1V.16H260.8q6.68,0,10.45,3.12A10.75,10.75,0,0,1,275,12.05a11.31,11.31,0,0,1-2.32,7.27,11.86,11.86,0,0,1-6.57,4l9.41,13ZM260.9,23q6.32,0,9.7-2.84A10,10,0,0,0,274,12.05,10,10,0,0,0,270.6,4q-3.39-2.83-9.7-2.84H249.58V23Z" />
                <path d="M307.56,25.45H285.42l-5.07,10.91h-1.19L296,.16h1l16.81,36.2h-1.19Zm-.47-1L296.49,1.55l-10.6,22.91Z" />
                <path d="M347,18.62h1V31.86a15,15,0,0,1-5.5,3.47,20.11,20.11,0,0,1-7,1.19,19.47,19.47,0,0,1-9.6-2.38,17.47,17.47,0,0,1-6.69-6.55,18.14,18.14,0,0,1-2.43-9.33,18.2,18.2,0,0,1,2.43-9.34,17.51,17.51,0,0,1,6.69-6.54A19.36,19.36,0,0,1,335.54,0a20.45,20.45,0,0,1,7,1.16A14.81,14.81,0,0,1,348,4.6l-.62.73Q342.84,1,335.54,1a18.14,18.14,0,0,0-9,2.28,16.69,16.69,0,0,0-6.36,6.23,17.17,17.17,0,0,0-2.3,8.77,17.19,17.19,0,0,0,2.3,8.77,16.77,16.77,0,0,0,6.36,6.23,18.24,18.24,0,0,0,9,2.27q7.18,0,11.48-4.08Z" />
              </g>
              <g>
                <path d="M4.54,50.54a2.93,2.93,0,0,1,1.09,1.12A3.28,3.28,0,0,1,6,53.27a3.35,3.35,0,0,1-.39,1.62A2.93,2.93,0,0,1,3,56.41a2.86,2.86,0,0,1-1.71-.52A2.83,2.83,0,0,1,.24,54.48v1.9H0V47.47H.24v4.59a2.8,2.8,0,0,1,1.06-1.4A2.91,2.91,0,0,1,3,50.14,3,3,0,0,1,4.54,50.54Zm-.11,5.27a2.59,2.59,0,0,0,1-1,3.35,3.35,0,0,0,0-3,2.65,2.65,0,0,0-1-1A2.86,2.86,0,0,0,3,50.37a2.79,2.79,0,0,0-1.41.36,2.61,2.61,0,0,0-1,1,3.35,3.35,0,0,0,0,3,2.54,2.54,0,0,0,1,1A2.71,2.71,0,0,0,3,56.19,2.77,2.77,0,0,0,4.43,55.81Z" />
                <path d="M12.52,50.17l-3.3,7.2a2.71,2.71,0,0,1-.76,1.06,1.61,1.61,0,0,1-1,.31,1.72,1.72,0,0,1-1.34-.55L6.3,58a1.58,1.58,0,0,0,.53.39,1.6,1.6,0,0,0,.65.12,1.33,1.33,0,0,0,.83-.27A2.44,2.44,0,0,0,9,57.3l.43-.95L6.56,50.17h.28l2.7,5.93,2.7-5.93Z" />
                <path d="M16.49,56.38V48.22h3a3.93,3.93,0,0,1,2.94,1.13,4.52,4.52,0,0,1,0,5.89,3.9,3.9,0,0,1-2.94,1.14Zm3-6.52H18.19v4.87H19.5a2.13,2.13,0,0,0,1.68-.67,2.57,2.57,0,0,0,.6-1.76,2.55,2.55,0,0,0-.6-1.75A2.11,2.11,0,0,0,19.5,49.86Z" />
                <path d="M24.3,53.39a3.07,3.07,0,1,1,.9,2.22A3,3,0,0,1,24.3,53.39Zm2.08-1.07a1.57,1.57,0,0,0,0,2.14,1.35,1.35,0,0,0,1,.45,1.33,1.33,0,0,0,1-.44,1.56,1.56,0,0,0,.41-1.08,1.52,1.52,0,0,0-.41-1.07,1.33,1.33,0,0,0-1-.44A1.38,1.38,0,0,0,26.38,52.32Z" />
                <path d="M33.17,50.4v.76a1.75,1.75,0,0,1,1.57-.88,2,2,0,0,1,1.53.64,2.27,2.27,0,0,1,.58,1.61v3.85H35.21V53a1.13,1.13,0,0,0-.28-.8,1,1,0,0,0-.74-.3,1,1,0,0,0-.72.27,1.07,1.07,0,0,0-.3.76v3.47H31.54v-6Z" />
                <path d="M38.16,56.38V48.1h1.63v4.56l1.63-2.26h1.92l-1.8,2.41,1.8,3.57H41.45L40.32,54l-.53.72v1.68Z" />
                <path d="M49.26,53.91H45.2a1.35,1.35,0,0,0,.54.82,1.76,1.76,0,0,0,1,.3,3.64,3.64,0,0,0,2-.56V56a4.65,4.65,0,0,1-2.16.47,3,3,0,0,1-2.22-.87,3,3,0,0,1-.87-2.25,3.1,3.1,0,0,1,.85-2.21,2.82,2.82,0,0,1,2.14-.9,2.67,2.67,0,0,1,2,.82,2.88,2.88,0,0,1,.77,2A3.47,3.47,0,0,1,49.26,53.91Zm-1.84-2a1.27,1.27,0,0,0-.87-.29,1.42,1.42,0,0,0-.91.3,1.11,1.11,0,0,0-.44.73h2.58A.9.9,0,0,0,47.42,51.93Z" />
                <path d="M50.38,58.84l1.36-2.92L49.55,50.4h1.83l1.26,3.46L54,50.4h1.84l-3.55,8.44Z" />
                <path d="M60.41,54.49v1.73a2.8,2.8,0,0,1-1.32.29,3,3,0,0,1-3.16-3.12,3,3,0,0,1,.88-2.23,3.09,3.09,0,0,1,2.28-.88,2.86,2.86,0,0,1,1.32.28v1.73a2.2,2.2,0,0,0-1.32-.41,1.43,1.43,0,0,0-1.08.44,1.49,1.49,0,0,0-.42,1.07A1.53,1.53,0,0,0,58,54.47a1.43,1.43,0,0,0,1.08.44A2.12,2.12,0,0,0,60.41,54.49Z" />
                <path d="M63.22,48.1v8.28H61.57V48.1Z" />
                <path d="M64.72,49.35a1,1,0,0,1,0-1.38,1,1,0,0,1,1.37,0,1,1,0,0,1,0,1.38,1,1,0,0,1-1.37,0Zm1.5,1v6H64.58v-6Z" />
                <path d="M69.22,50.4v.87a2,2,0,0,1,1.83-1,2.62,2.62,0,0,1,2,.89,3.16,3.16,0,0,1,.81,2.22,3.2,3.2,0,0,1-.81,2.23,2.64,2.64,0,0,1-2,.89,2.11,2.11,0,0,1-1.82-.9v3.23H67.58V50.4Zm.43,1.93a1.43,1.43,0,0,0-.43,1.06,1.47,1.47,0,0,0,.43,1.07,1.5,1.5,0,0,0,2.12,0,1.5,1.5,0,0,0,.42-1.07,1.46,1.46,0,0,0-.42-1.06,1.4,1.4,0,0,0-1-.44A1.45,1.45,0,0,0,69.65,52.33Z" />
              </g>
            </g>
          </g>
        </SvgIconStyled>
      </Logo>
      <Button onClick={() => setOpen(!open)} size="large">
        <SvgIcon focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M3 18h12v-2H3v2zM3 6v2h18V6H3zm0 7h18v-2H3v2z"></path>
        </SvgIcon>
      </Button>
      <SideDrawer open={open} setOpen={setOpen} />
    </Root>
  );
};

export default Header;
