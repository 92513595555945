import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Path = () => {
  const { search } = useLocation();
  const [path, setPath] = useState({ filter: "" });

  useEffect(() => {
    setPath(
      search !== ""
        ? JSON.parse(decodeURIComponent(search.replace(`?filter=`, "")))
        : ""
    );
  }, [search]);

  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        marginBottom: "35px",
      }}
      display="flex"
      flexDirection="row-reverse"
      p={1}
      m={1}
    >
      <Box
        sx={{
          width: "45%",
          borderBottom: "1px solid #fff",
          paddingLeft: "10px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 300,
            fontSize: "14px",
          }}
        >
          {" "}
          {path.filter}
        </Typography>
      </Box>
    </Box>
  );
};

export default Path;
