/* eslint-disable array-callback-return */
import { styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import Snackbar from "@mui/material/Snackbar";
import SvgIcon from "@mui/material/SvgIcon";
import { useState } from "react";
import CopyPopover from "./CopyPopover";
import TagButton from "./TagButton";
import Waveform from "./Waveform";

const SvgButton = styled(SvgIcon)(() => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
}));
const ButtonIcon = styled(IconButton)(({ theme }) => ({
  width: "80px",
  height: "80px",
  [theme.breakpoints.down("md")]: {
    width: "40px",
    height: "40px",
  },
}));

const Root = styled("div")(() => ({
  maxWidth: "100%",
  minHeight: "142px",
  border: "1px solid #fff",
  background: "#141414",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  marginBottom: "40px",
}));

const Time = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: "12px",
  marginLeft: "15px",
}));
const PopperStyled = styled(Popper)(() => ({
  zIndex: 3,
  background: "#141414",
  border: "1px solid #fff",
  justifyContent: "space-around",
  padding: "10px",
  paddingLeft: "20px",
}));

const Description = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: "12px",
  maxWidth: "200px",
  maxHeight: "200px",
  lineHeight: "14.5px",
  boxOrient: "vertical",
  display: "block",
  overflow: "hidden !important",
  textOverflow: "ellipsis",
  lineClamp: 4,
}));

const FlexCenter = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const FlexCenterWithBlockMd = styled(FlexCenter)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const ListCard = ({ track }) => {
  const [snackState, SetSnackState] = useState(false);
  const [playing, setPlay] = useState(false);
  const [time, setTime] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handlePlayPause = () => {
    setPlay(!playing);
  };
  let letters = 0;
  let displayedTags = 0;
  let nonDisplayedTags = [];
  return (
    <Root>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={2}>
          <ButtonIcon onClick={handlePlayPause} size="large">
            <SvgButton focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              {!playing ? (
                <path
                  transform="scale(0.5, 0.5)"
                  d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm-4 29V15l12 9-12 9z"
                ></path>
              ) : (
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14H9V8h2v8zm4 0h-2V8h2v8z"></path>
              )}
            </SvgButton>
          </ButtonIcon>
        </Grid>
        <Grid item xs={5}>
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Waveform
                key={track.name}
                url={track.url}
                status={playing}
                setTime={setTime}
              />
              <Time fontSize={12}>
                {time[0]} : {time[1] < 10 ? "0" + time[1] : time[1]}
              </Time>
            </div>
            <FlexCenter>
              <Box component="div" textOverflow="ellipsis" overflow="hidden">
                {track.name}
              </Box>
            </FlexCenter>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            <Description gutterBottom={true}>{track.description}</Description>
            <FlexCenterWithBlockMd style={{ marginTop: 5 }}>
              {track.tags &&
                track.tags.map((e) => {
                  if (e.length + letters > 14) {
                    nonDisplayedTags.push(e);
                  } else {
                    displayedTags = +1;
                    letters += e.length;
                    return <TagButton key={e} name={e} />;
                  }
                })}
              {track.tags && displayedTags !== track.tags.length && (
                <FlexCenterWithBlockMd>
                  {nonDisplayedTags.length > 0 && (
                    <Button variant="outlined" onClick={handleClick}>
                      +{nonDisplayedTags.length}
                    </Button>
                  )}
                  <PopperStyled open={open} anchorEl={anchorEl}>
                    {nonDisplayedTags.map((e) => (
                      <TagButton key={e} name={e} />
                    ))}
                  </PopperStyled>
                </FlexCenterWithBlockMd>
              )}
            </FlexCenterWithBlockMd>
          </div>
        </Grid>
        <Grid item xs={1}>
          <CopyPopover name={track.name} />
        </Grid>
      </Grid>
      <Snackbar
        autoHideDuration={50000}
        open={snackState}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id"> Name Copied to clipboard </span>}
        onClose={() => SetSnackState(false)}
      />
    </Root>
  );
};

export default ListCard;
