import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const TagButton = ({ name, big }) => {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate({
      pathname: `/search`,
      search: `filter={"filter":"${name === "All" ? "" : name}"}`,
    });
  };

  return (
    <Button
      sx={
        big && {
          height: "48px",
          padding: "20px",
          margin: "10px",
        }
      }
      onClick={handleClick}
      variant="outlined"
    >
      {name}
    </Button>
  );
};

export default TagButton;
