import { createTheme, adaptV4Theme } from "@mui/material/styles";
// import { borders } from '@mui/system';

// Create a theme instance.
export const theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: "dark",
      background: {
        default: "#141414",
      },
    },
    typography: {
      fontFamily: "Montserrat",
      h5: {
        fontWeight: "bold",
        color: "#252056",
      },
      subtitle2: {
        fontWeight: 500,
        color: "#252056",
      },
    },
    overrides: {
      MuiPopover: {
        paper: {
          background: "#141414",
          border: "1px solid #fff",
          borderRadius: 0,
        },
      },
      MuiSnackbarContent: {
        root: {
          minWidth: "218px !important",
          borderRadius: 0,
          fontWeight: "bold",
        },
      },
      MuiButton: {
        root: {
          borderRadius: 0,
          minWidth: "unset",
          maxHeight: 16,
          marginRight: 10,
        },
        outlined: {
          padding: "0px 5px",
        },
        text: {
          fontSize: 12,
        },
      },
      MuiInput: {
        underline: {
          "&:after": {
            borderBottom: "none",
          },
        },
        input: {
          fontWeight: 300,
        },
      },
    },
  })
);
