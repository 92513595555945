import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useRef } from "react";
import TrackList from "./TrackList";

const SVG = styled("svg")(({ theme }) => ({
  width: "880px",
  height: "670px",
  opacity: 0.3,
  position: "absolute",
  left: "-241px",
  top: "196px",
  zIndex: 1,
  [theme.breakpoints.down("md")]: {
    width: "616px",
    height: "469px",
  },
}));

const Body = () => {
  const listRef = useRef();
  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
      }}
      display="flex"
      flexDirection="row-reverse"
      p={1}
      m={1}
    >
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width="851.14"
        height="649.15"
        viewBox="0 0 851.14 649.15"
      >
        <title>c</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <path
                d="M850.64,324.58c0-30.27-49.57-51.1-71.5-61.85C746,246.49,711.5,232.5,680,213.08c-25.87-16-40.5-36.15-60.16-58.34-17.35-19.57-36.52-37.57-56.33-54.62C494.93,41.07,416.77.59,324.58.5,148,.33.5,147.89.5,324.58S148,648.82,324.58,648.65c92.79-.09,168.68-41.89,240.1-97.6,20.35-15.87,40.39-32.42,58.49-50.85C643.52,479.48,659.1,459.12,684,443c31-20,64.75-35.5,97.08-53.22C803.1,377.73,850.6,354.93,850.64,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,638.2C153.73,638.37,11,495.57,11,324.58S153.73,10.78,324.58,11c89,.09,164.67,39,230.76,96.18,40.57,35.09,70.35,81.33,117,108.56,32.54,19,67.54,33.2,101.16,50.07,21.39,10.74,66,29.65,66,58.82s-42.8,49.89-64.23,61.83c-32.82,18.28-67.14,33.86-99.27,53.38C630.49,467.44,598.19,511,556.4,543.88,487.65,598,414.14,638.11,324.58,638.2Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M827.24,324.58c-1-28.32-42.14-46.59-64.67-58.06-33.42-17-68.17-31.33-100.83-49.82C615.52,190.53,586.37,148.38,546.88,114c-63.54-55.36-136.74-92.39-222.3-92.57-165-.35-303.17,137.88-303.17,303.17s138,303.33,303.17,303.16c86.23-.09,157.31-38.46,223.24-91,41.31-32.89,73.54-73.5,119.72-100.15,31.08-17.93,63.74-32.93,95.09-50.36C783.3,374.79,828.24,352.45,827.24,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,617.29c-159.26.35-292.72-133.13-292.72-292.71S165.31,31.52,324.58,31.86c82.16.18,152.83,35.42,213.64,88.9,39.2,34.48,68.32,72.82,115.11,98.34,33.19,18.1,68.16,32.62,101.56,50.3,21.1,11.17,58.11,29.08,59.05,55.18S774.8,371.51,754.79,383c-31.36,17.94-64.27,33-95.84,50.55-46.68,25.95-79,63.14-120,96.23C475.89,580.8,407.37,617.11,324.58,617.29Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M799.74,324.58c0-23.79-34.06-41.69-53.29-52.32C715.18,255,682.23,241,650.55,224.49c-26.19-13.63-53-28.54-74.14-49.45-15.92-15.71-30.22-32.64-47-47.57C471.6,76,403.25,42.32,324.58,42.32,170.7,42.31,42.32,170.7,42.32,324.58S170.7,606.84,324.58,606.84c79.37,0,145.58-34.66,205.46-84.1,16.27-13.44,30.67-28.36,46-42.79,21.67-20.46,48-35.47,74-49.54,31.89-17.2,65-32.31,96.16-50.75C765.75,368.12,799.76,348.92,799.74,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,596.38C177,596.38,52.77,471.91,52.77,324.58S177,52.77,324.58,52.77c75.16,0,141.07,31.75,196,81.39C537,149,551,166.59,568,180.85c20.58,17.32,44.44,30.63,68.18,43,31.62,16.51,64.44,30.75,95.57,48.22,19.2,10.77,53,28.94,53,52.48,0,24.09-33.92,43.44-53.47,55C702,397,671.1,411.38,641.06,427.29c-25.6,13.56-51.82,27.58-74.2,46.19-16,13.28-30,28.82-45.84,42.27C464.23,563.84,400.51,596.38,324.58,596.38Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M769.11,324.58c0-23.78-34.57-42.45-54.09-53.51-27.59-15.62-56.54-28.74-84.72-43.22-25-12.84-50-26.33-72.41-43.43C541,171.5,527.4,155.26,511.8,140.91c-52-47.86-115.68-77.68-187.22-77.69C182.74,63.22,63.22,183,63.22,324.58S182.74,585.93,324.58,585.93c72.24,0,134-30.51,187.5-77.19,15.86-13.84,30-29.34,47.1-41.74C582,450.46,607,437.12,631.91,424.13c28.07-14.64,56.77-28.3,84-44.55C735.38,367.92,769.13,348.44,769.11,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,575.47c-136.15,0-250.9-114.94-250.9-250.89s114.75-250.9,250.9-250.9c67.92,0,129.63,27.94,178.64,74,36.63,34.47,73.22,59.53,118.31,82.72,27.82,14.31,56.37,27.46,83.19,43.6,18.14,10.91,48.19,28.51,48.2,50.54s-29.46,40.27-47.56,51.65c-26.49,16.66-54.84,30.25-82.58,44.67-44.45,23.1-82.49,47.27-119.42,80.78C453.33,547.06,393.05,575.47,324.58,575.47Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M736.31,324.58c0-20.09-25.49-36.81-42.26-47.49-26-16.54-54-29.8-81.36-43.9C569.88,211.11,530,188,495,154.71c-46.47-44.21-105.73-70.58-170.4-70.58-130.43,0-240.45,110.22-240.45,240.45S194.15,565,324.58,565C389.64,565,448,538.37,495,494.52c35.15-32.73,76.14-54.89,118.6-76.93,27.29-14.17,55.18-27.78,80.88-44.74C711.21,361.83,736.31,344.7,736.31,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,554.57c-124.3.43-230-106.13-230-230s105.69-230.42,230-230c62.51.21,117.07,25.75,162.69,67.35,34.4,31.36,75.62,52.75,116.64,74.17,24.93,13,50.39,25.53,74,40.91,16.29,10.6,41.52,27.78,41.5,47.56,0,39.5-80.42,71.58-111.9,88-41.62,21.77-85.42,42.55-120.27,74.62C441.91,529,386.86,554.35,324.58,554.57Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M702.33,324.58c0-39.25-78.71-70.3-110.18-86.95C553.74,217.3,514.2,197.34,480.7,169.3c-45.81-38.33-95-64-156.12-64.26C204.86,104.6,105,206.18,105,324.58s99.82,220,219.54,219.53c61.12-.22,110.29-25.94,156.12-64.26,33.48-28,73.49-47.33,111.86-67.61C624.27,395.48,702.27,364,702.33,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,533.66c-113.88.42-209.09-96.19-209.09-209.08S210.7,115.07,324.58,115.49c59.93.23,104.28,27,151.12,61.21,35.77,26.11,74.84,46.34,113.7,67.39,28.46,15.41,95.84,44.54,95.79,80.49s-67,65.4-95.56,80.88c-38.87,21-78.14,40.87-113.93,67C428.86,506.65,384.51,533.43,324.58,533.66Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M668.11,324.58c.06-35.88-64.75-64.55-92.85-80-35.1-19.37-69.9-38.52-103.49-60.44-47-30.64-89-57.79-147.19-58.14C216.17,125.29,126,217.38,126,324.58S216.17,523.86,324.58,523.2c58.18-.35,100.23-27.5,147.19-58.14,33.61-21.93,68.45-40.93,103.58-60.27C603.57,389.27,668.05,360.49,668.11,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,512.75C222.19,513,136.4,427.16,136.4,324.58S222.19,136.17,324.58,136.4c55.67.13,97.72,27.54,143.94,55.11,33.46,20,67.42,39,101.25,58.3,26,14.85,81.5,41.31,81.45,74.77s-55.37,59.94-81.43,74.8C536,418.67,502,437.69,468.52,457.64,422.3,485.21,380.25,512.62,324.58,512.75Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M634.63,324.58c0-32-48.57-56.09-72.77-70.75-31.65-19.19-64.05-37-96.41-54.93-45-25-87.8-51.92-140.87-52-96.71-.23-177.72,80.82-177.72,177.72s81,177.94,177.72,177.71c53.07-.12,95.84-27,140.87-52,32.36-18,64.76-35.73,96.41-54.93C586.07,380.64,634.59,356.51,634.63,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,491.84c-91,.23-167.27-76.09-167.27-167.26s76.29-167.5,167.27-167.27c50.53.13,94.13,26.12,137.72,49,31.44,16.49,63.23,32.69,93.31,51.6C578,272,618.49,296.1,618.48,324.58S578,377.16,555.61,391.25c-30.08,18.91-61.87,35.11-93.31,51.61C418.71,465.72,375.11,491.72,324.58,491.84Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M605.08,324.58c0-27.13-35.49-48.88-57.22-62.57-28.57-18-58.94-33.19-89.1-48.32-42.06-21.11-86-45.79-134.18-45.92-85.27-.24-156.82,71.35-156.82,156.81s71.55,157.05,156.82,156.81c48.19-.14,92.12-24.82,134.18-45.93,30.16-15.13,60.53-30.32,89.1-48.32C569.59,373.45,605.08,351.7,605.08,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,470.93c-79.41.37-146.36-66.61-146.36-146.35s66.95-146.73,146.36-146.36c46,.21,89.44,23.16,130,42.87,28.49,13.82,57.11,28,83.87,45,20.66,13.13,53.21,34,53.21,58.5s-32.55,45.36-53.21,58.49c-26.76,17-55.38,31.17-83.87,45C414,447.77,370.57,470.72,324.58,470.93Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M575.81,324.58c0-22.23-29.37-42-48.47-54.39-24.67-15.94-51.16-29.13-77.67-41.71C410.8,210,368.33,188.9,324.58,188.67c-73.06-.37-135.91,62.7-135.91,135.91s62.85,136.28,135.91,135.9c43.75-.23,86.22-21.37,125.09-39.81,26.51-12.57,53-25.77,77.67-41.71C546.44,366.61,575.81,346.8,575.81,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,450c-67.67.09-125.45-57.93-125.45-125.44s57.78-125.53,125.45-125.45c41.46.05,82.45,19.53,119.23,36.75,33.55,15.7,114.06,48.26,114,88.7,0,40.24-80.09,72.82-114.06,88.72C407.09,430.48,365.91,450,324.58,450Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M538.2,324.58c0-37.31-70.27-66.89-101.27-81.34-34.6-16.12-73.49-33.6-112.35-33.66-61.93-.1-115,53.2-115,115s53.07,115.09,115,115c38.86-.06,77.75-17.54,112.35-33.66C467.93,391.47,538.2,361.88,538.2,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,429.12c-57,.54-104.54-48.68-104.54-104.54S267.61,219.5,324.58,220c35.88.34,72.41,15.62,104.48,30.63,28,13.09,88.2,39.51,88.2,73.91s-60.23,60.81-88.2,73.9C397,413.49,360.46,428.78,324.58,429.12Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M495.44,324.58c0-31.45-50.14-54.59-75.27-66.51-29.45-14-62.72-27.37-95.59-27.58-51-.33-94.09,42.81-94.09,94.09S273.6,419,324.58,418.66c32.87-.21,66.14-13.6,95.59-27.58C445.3,379.16,495.44,356,495.44,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,408.21c-45.31.34-83.64-38-83.64-83.63s38.33-84,83.64-83.64c29.48.22,59.39,11.65,85.78,24.48,22.06,10.73,62.75,30.78,62.75,59.16S432.42,373,410.36,383.73C384,396.56,354.06,408,324.58,408.21Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M450.7,324.58c0-44.74-89.53-72.95-126.12-73.18-39.62-.25-73.18,33.33-73.18,73.18S285,398,324.58,397.75C361.17,397.53,450.7,369.31,450.7,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,387.3c-33.37.16-62.73-29.4-62.73-62.72s29.36-62.89,62.73-62.73c30.6.15,104,23.58,104,62.73S355.18,387.15,324.58,387.3Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M407.18,324.58c0-32.15-57.1-52-82.6-52.27-28.18-.32-52.27,25-52.27,52.27s24.09,52.58,52.27,52.27C350.08,376.56,407.18,356.73,407.18,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,366.39c-22.54.26-41.82-19-41.82-41.81S302,282.5,324.58,282.76c20.06.23,62.28,17.1,62.28,41.82S344.64,366.16,324.58,366.39Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M368.05,324.58c0-19.49-27.44-31.24-43.47-31.37-16.3-.13-31.37,15-31.37,31.37s15.07,31.49,31.37,31.36C340.61,355.81,368.05,344.06,368.05,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M324.58,345.48c-11.28.1-20.91-9.53-20.91-20.9s9.63-21,20.91-20.91c11.55.1,26.55,8,26.55,20.91S336.13,345.38,324.58,345.48Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M336.51,324.58c-.23-5.78-6.16-10.46-11.93-10.46a10.46,10.46,0,1,0,0,20.91C330.35,335,336.74,330.35,336.51,324.58Z"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
              />
            </g>
          </g>
        </g>
      </SVG>

      <TrackList listRef={listRef} test={window.location.search} />
    </Box>
  );
};

export default Body;
