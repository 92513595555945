import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyButton = styled(IconButton)(() => ({
  padding: 0,
  marginRight: 10,
}));

const StyledSvgIcon = styled(SvgIcon)(() => ({
  width: 20,
  height: 20,
}));

const FlexDiv = styled("div")`
  display: flex;
`;

export default function CopyPopover(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleName = (name, code) => {
    if (code) {
      return `
            const newTrack = new SoundDrag.Track(
                {
                  name:"${name}",
                },
                {
                  id: "myClip"
                }
            );
            `;
    }

    return name;
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <CopyButton onClick={handleClick} size="large">
        <StyledSvgIcon
          focusable="false"
          viewBox="0 0 256 256"
          aria-hidden="true"
        >
          <path
            transform="scale(0.5, 0.5)"
            d="M324.267 119.467V0H153.6v409.6H443.733V119.467z"
          />
          <path
            transform="scale(0.5, 0.5)"
            d="M358.4 0v85.333h85.333zM119.467 443.733V102.4h-51.2V512H358.4v-68.267z"
          />
        </StyledSvgIcon>
      </CopyButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 216,
            width: "20ch",
          },
        }}
      >
        <MenuItem>
          <CopyToClipboard
            text={handleName(props.name, false)}
            onCopy={handleClose}
          >
            <FlexDiv>
              <StyledSvgIcon
                focusable="false"
                viewBox="0 0 256 256"
                aria-hidden="true"
              >
                <path
                  transform="scale(0.5, 0.5)"
                  d="M324.267 119.467V0H153.6v409.6H443.733V119.467z"
                />
                <path
                  transform="scale(0.5, 0.5)"
                  d="M358.4 0v85.333h85.333zM119.467 443.733V102.4h-51.2V512H358.4v-68.267z"
                />
              </StyledSvgIcon>
              <Typography>Copy name</Typography>
            </FlexDiv>
          </CopyToClipboard>
        </MenuItem>
        <MenuItem>
          <CopyToClipboard
            text={handleName(props.name, true)}
            onCopy={handleClose}
          >
            <FlexDiv>
              <StyledSvgIcon
                focusable="false"
                viewBox="0 0 256 256"
                aria-hidden="true"
              >
                <path
                  transform="scale(0.5, 0.5)"
                  d="M324.267 119.467V0H153.6v409.6H443.733V119.467z"
                />
                <path
                  transform="scale(0.5, 0.5)"
                  d="M358.4 0v85.333h85.333zM119.467 443.733V102.4h-51.2V512H358.4v-68.267z"
                />
              </StyledSvgIcon>
              <Typography>Copy Incident</Typography>
            </FlexDiv>
          </CopyToClipboard>
        </MenuItem>
      </Menu>
    </div>
  );
}
