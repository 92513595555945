import { useEffect, useRef, useState } from "react";

import WaveSurfer from "wavesurfer.js";

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "rgba(255,255,255,0.6)",
  progressColor: "rgba(255,255,255,1)",
  cursorColor: "transparent",
  barWidth: 1,
  barRadius: 0,
  responsive: true,
  height: 50,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
});

export default function Waveform({ url, status, setTime }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [volume, setVolume] = useState(0.5);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    if (url) {
      const options = formWaveSurferOptions(waveformRef.current);
      wavesurfer.current = WaveSurfer.create(options);
      wavesurfer.current.load(url);
      wavesurfer.current.on("ready", function () {
        // make sure object stillavailable when file loaded
        if (wavesurfer.current) {
          wavesurfer.current.setVolume(volume);
          setVolume(volume);
          const minutes = Math.floor(wavesurfer.current.getDuration() / 60);
          const seconds = Math.floor(
            wavesurfer.current.getDuration() - minutes * 60,
          );
          setTime([minutes, seconds]);
        }
      });

      // Removes events, elements and disconnects Web Audio nodes.
      // when component unmount
      return () => wavesurfer.current.destroy();
    }
  }, []);

  useEffect(() => {
    url && wavesurfer.current.playPause();
  }, [status]);

  return url ? (
    <div id="waveform" style={{ width: "75%", height: 50 }} ref={waveformRef} />
  ) : (
    <div>DEAD LINK</div>
  );
}
