import { styled, Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";

const Root = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "absolute",
  bottom: "10px",
  left: "30px",
}));

const Copy = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 300,
  color: "#999999",
  marginRight: "30px",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
    marginRight: "20px",
  },
}));

const Links = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 700,
  color: "#FFF",
  marginRight: "30px",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    marginRight: "10px",
  },
}));

const Link = Links.withComponent(MuiLink);

const Footer = () => {
  return (
    <Root>
      <Copy>©2022-DonkeyClip</Copy>
      <Links>
        <Link
          target={"blank"}
          href="https://github.com/kissmybutton/motorcortex-anime"
        >
          How to Use
        </Link>
        <Link target={"blank"} href="https://motorcortexjs.com/">
          MotorCortex
        </Link>
        <Link target={"blank"} href="https://donkeyclip.com/">
          Donkeyclip
        </Link>
      </Links>
    </Root>
  );
};

export default Footer;
