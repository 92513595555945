import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import ListCard from "./ListCard";
import { useApi } from "./useFetch";

const { REACT_APP_API_HOST } = process.env;

const Root = styled("div")(({ theme }) => ({
  flex: 1,
  maxWidth: "700px",
  display: "flex",
  zIndex: 1,
  overflow: "scroll",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    appearance: "none",
    height: 0,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "44%",
  },
}));
const Line = styled("div")(() => ({
  height: "25vh",
  width: "1px",
  background: "#fff",
}));

const AutoSizerWrapper = styled("div")(() => ({
  width: "100%",
  height: "77vh",
}));

const PaginationWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flex: 2,
  textAlign: "center",
  minWidth: "100px",
  [theme.breakpoints.down("md")]: {
    minWidth: "65px",
  },
}));

const Row = ({ data, index, style }) => {
  return (
    <div style={style}>
      <ListCard style={style} trackId={index} track={data[index]} />
    </div>
  );
};

const TrackList = ({ listRef }) => {
  const [current, setCurrent] = useState();
  const [response, setResponse] = useState();
  const { search } = useLocation();
  const { state, data } = useApi(
    `${REACT_APP_API_HOST}/sounds${search === "" ? "?filter={}" : search}`
  );
  useEffect(() => {
    state === "SUCCESS" && setResponse(data.sounds);
    // eslint-disable-next-line
  }, [data]);

  return (
    <Root>
      <AutoSizerWrapper>
        {response !== undefined && (
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={listRef}
                className="List"
                height={height}
                itemCount={response.length}
                itemSize={182}
                width={width}
                onItemsRendered={({ visibleStopIndex }) => {
                  setCurrent(visibleStopIndex + 1);
                }}
                itemData={response}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        )}
      </AutoSizerWrapper>
      {response !== undefined && (
        <PaginationWrapper>
          <Line />
          <Typography>
            {current} of {response.length}
          </Typography>
          <Line />
        </PaginationWrapper>
      )}
    </Root>
  );
};

export default TrackList;
