import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "./theme";
import Header from "./components/Header";
import Body from "./components/Body";
import Path from "./components/Path";
import Footer from "./components/Footer";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Path />
        <Body />
        <Footer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
